import Repository from "../repository";

const resource = "/synchro";

export default {
  login_check(payload) {
    return Repository.post(`${resource}/login_check`, payload);
  },
  sync_cast_simple(payload) {
    return Repository.post(`${resource}/sync_cast_simple`, payload);
  },
  sync_update_cast(payload) {
    return Repository.post(`${resource}/sync_update_cast`, payload);
  },
  sync_cast(payload) {
    return Repository.post(`${resource}/sync_cast`, payload);
  },
  sync_schedule(payload) {
    return Repository.post(`${resource}/sync_schedule`, payload);
  },
  get_cast_order(payload) {
    return Repository.post(`${resource}/get_cast_order`, payload);
  },
  sync_cast_order(payload) {
    return Repository.post(`${resource}/sync_cast_order`, payload);
  },
  get_schedule(payload) {
    return Repository.post(`${resource}/get_schedule`, payload);
  },
  sync_working(payload) {
    return Repository.post(`${resource}/sync_working`, payload);
  },
  get_authorization_url(payload) {
    return Repository.post(`${resource}/get_authorization_url`, payload);
  },
  get_access_token(payload) {
    return Repository.post(`${resource}/get_access_token`, payload);
  },
  sns_delete_link(payload) {
    return Repository.post(`${resource}/sns_delete_link`, payload);
  },
  sns_update_user_profile(payload) {
    return Repository.post(`${resource}/sns_update_user_profile`, payload);
  },
  admin_login_check(payload) {
    return Repository.post(`${resource}/admin/login_check`, payload);
  },
};
