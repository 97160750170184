<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import { computed } from "@vue/composition-api";
import { useRouter } from "@/utils";
import LayoutBlank from "@/layouts/blank.vue";
import LayoutContent from "@/layouts/content.vue";
import AdminLayoutContent from "@/layouts/admin/content.vue";

export default {
  components: {
    LayoutBlank,
    LayoutContent,
    AdminLayoutContent,
  },
  setup() {
    const { route } = useRouter();

    const resolveLayout = computed(() => {
      if (route.value.name === null) return null;
      if (route.value.meta.layout === "blank") return "layout-blank";
      if (route.value.meta.layout === "admin") return "admin-layout-content";

      return "layout-content";
    });

    return {
      resolveLayout,
    };
  },
};
</script>

<style>
/** フォント設定 */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap");

.v-application {
  font-family: -apple-system, "Noto Sans JP", "Helvetica Neue", "Segoe UI",
    "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-size: 100%;
}
.card-wrapper {
  padding: 0.5em;
}
.v-card__text h3 {
  margin-top: 1em;
  color: black;
}
.v-card__text .row > div {
  padding: 6px;
}
.stepper-scrollable-content {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  max-height: calc(100% - 71px);
}
.mini {
  font-size: 0.75em;
}
.big {
  font-size: 1.25em;
}
.font-mini {
  font-size: 9px;
}
.font-12 {
  font-size: 12px;
}
.text-align-middle {
  vertical-align: middle;
}
/* 入力項目の文字の下側が切れることがあるため高さ調整 */
.v-text-field input {
  line-height: 24px;
}
.v-select__selections {
  line-height: 20px;
}
.v-application--is-ltr .v-data-footer__select .v-select {
  margin: 13px 0 13px 10px !important;
}
.v-application--is-ltr .v-data-footer__pagination {
  margin: 0 8px 0 24px !important;
}
/* キャスト情報編集画面 */
.item-card {
  width: 100%;
  max-width: 1200px;
}
/* @media screen and (max-width: 1800px) {
  .item-card {
    width: 100%;
    max-width: 100%;
  }
} */
.item-card-backcolor {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}
/* .item-card-backcolor:hover {
  background-color: #f8f8ff !important;
} */
.item-req {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  line-height: 1em;
  border: 2px solid red;
  border-radius: 3px;
  margin-right: 4px;
  margin-bottom: 4px;
}
.item-req div:nth-of-type(1) {
  display: inline-block;
  padding: 4px;
  color: white;
  background-color: red;
}
.item-req div:nth-of-type(2) {
  display: inline-block;
  padding: 4px;
  color: black;
  background-color: white;
}
.item-comment {
  display: inline-block;
  font-size: 12px;
  color: red;
}

/* 横スクロール */
.x-scroll {
  white-space: nowrap;
  overflow-x: auto;
}

.text-red {
  color: red;
}
.text-blue {
  color: blue;
}
.text-white {
  color: white;
}
.text-green {
  color: green;
}
.text-v-blue {
  color: #2196f3;
}
.font-bold {
  font-weight: bold;
}
.bk-gray {
  background-color: #f8f8f8;
}
.right-input {
  text-align: right;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
