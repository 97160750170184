import Repository from "../repository";

const resource = "/casts";

export default {
  list(payload, headers = {}) {
    return Repository.get(`${resource}/list`, {
      params: payload,
      headers: headers,
    });
  },
  list_update(payload) {
    return Repository.get(`${resource}/list_update`, { params: payload });
  },
  list_profile(payload) {
    return Repository.get(`${resource}/list_profile`, { params: payload });
  },
  get_shopid(payload) {
    return Repository.get(`${resource}/get_shopid`, { params: payload });
  },
  list_with_schedule(payload) {
    return Repository.post(`${resource}/list_with_schedule`, payload);
  },
  check_samename(payload) {
    return Repository.post(`${resource}/check_samename`, payload);
  },
  create(payload) {
    return Repository.post(`${resource}/create`, payload);
  },
  update(payload) {
    return Repository.put(`${resource}/update`, payload);
  },
  delete(castId) {
    return Repository.delete(`${resource}/delete/${castId}`);
  },
  save_sort(payload) {
    return Repository.post(`${resource}/save_sort`, payload);
  },
  get_count(shopId) {
    return Repository.get(`${resource}/get_count/${shopId}`);
  },
  update_last_update(payload) {
    return Repository.put(`${resource}/update_last_update`, payload);
  },
};
