import Vue from "vue";
import {
  configure,
  extend,
  localize,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import ja from "vee-validate/dist/locale/ja";
import * as rules from "vee-validate/dist/rules";
import utilFunc from "@/common/utilFunc.js";
import utilDate from "@/common/utilDate.js";
import twitterText from "twitter-text";

const config = {
  bails: false,
  mode: "aggressive", //this is the default behavior and it validates whenever an input event is emitted.
};

configure(config);

for (let rule in rules) {
  extend(rule, rules[rule]);
}

// カスタムバリデーション
// URLチェック
extend("url", {
  validate(value) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(value);
  },
  message: "有効なURLではありません",
});

// カスタムバリデーション
// URLチェック
extend("url_https", {
  validate(value) {
    var pattern = new RegExp(
      "^(https:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(value);
  },
  message: "有効なURLではありません",
});

// チェックボックスの最大チェック個数チェック
extend("check_max", {
  validate(value, { length }) {
    return value.length <= length;
  },
  params: ["length"],
  message: "チェックは最大{length}つまでしか出来ません。",
});

// twitterアカウント
extend("twitter", {
  validate(value) {
    var pattern = /^(\w){5,15}$/;
    return pattern.test(value);
  },
  message: "有効なTwitterアカウントではありません",
});

// instagramアカウント
extend("instagram", {
  validate(value) {
    var pattern = /^[a-zA-Z][a-zA-Z0-9_]{2,29}$/;
    return pattern.test(value);
  },
  message: "有効なInstagramアカウントではありません",
});

// 指定月以内の日付チェック
extend("max_month_from_today", {
  validate(value, { length }) {
    const today = utilDate.getDateFormatLong(new Date());
    var tmp = utilFunc.getMonthLater(Number(length));
    tmp.setDate(tmp.getDate() - 1);
    const nextMonthDay = utilDate.getDateFormatLong(tmp);

    return value >= today && value <= nextMonthDay;
  },
  params: ["length"],
  message: "本日から最大{length}ヶ月後までしか設定出来ません。",
});

// 指定日以内の日付チェック
extend("max_day_from_today", {
  validate(value, { length, disabled = false }) {
    // disabled=trueの時はチェックしない
    if (disabled) return true;

    const today = utilDate.getDateFormatLong(new Date());
    var tmp = utilFunc.getDayLater(Number(length));
    const nextDay = utilDate.getDateFormatLong(tmp);

    return value >= today && value <= nextDay;
  },
  params: [{ name: "length" }, { name: "disabled", default: false }],
  message: "本日から最大{length}日後までしか設定出来ません。",
});

// IPアドレスv4
extend("ipv4port", {
  validate(value) {
    var ipv4Pattern =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    var portPattern = /^[0-9]{1,5}$/; // ポート番号は1から5桁の数字

    return (
      ipv4Pattern.test(value.split(":")[0]) &&
      portPattern.test(value.split(":")[1])
    );
  },
  message:
    "有効なIPアドレス(v4)とポート番号ではありません。##9.##9.##9.##9:#####形式",
});

// メンズエステマニアックス用twitterアカウント
extend("es_maniax_twitter_url", {
  validate(value) {
    var pattern = /^(https:\/\/twitter\.com\/|@)[a-zA-Z0-9_]{5,15}$/;
    return pattern.test(value);
  },
  message:
    "https://twitter.com/**** の形式、または @****(5文字〜15文字) の形式で入力してください。",
});

// メンズエステマニアックス用youtubeアカウント
extend("es_maniax_youtube_url", {
  validate(value) {
    var pattern = /^https:\/\/youtube\.com\//;
    return pattern.test(value);
  },
  message: "https://youtube.com/**** の形式で入力してください。",
});

// メンズエステマニアックス用instagramアカウント
extend("es_maniax_instagram_url", {
  validate(value) {
    var pattern = /^https:\/\/instagram\.com\//;
    return pattern.test(value);
  },
  message: "https://instagram.com/**** の形式で入力してください。",
});

// X文字数チェック
extend("x_post_length", {
  validate(value, { length, cnt }) {
    // const pt = twitterText.parseTweet(value);
    // const cnt = pt.weightedLength / 2;
    return cnt <= length;
  },
  params: ["length", "cnt"],
  message: "{length}文字以内で入力してください。",
});

// X文字数チェック
extend("x_post_length_base", {
  validate(value, { length }) {
    if (value == null || value == "") return true;
    const pt = twitterText.parseTweet(value);
    const cnt = pt.weightedLength / 2;
    return cnt <= length;
  },
  params: ["length"],
  message: "{length}文字以内で入力してください。",
});

// 日本語使用
localize("ja", ja);

// コンポーネント登録
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
