import Repository from "../repository";

const resource = "/result_logs";

export default {
  list(payload, headers = {}) {
    return Repository.post(`${resource}/list`, payload, { headers });
  },
  admin_list_date(payload) {
    return Repository.post(`${resource}/admin/list_date`, payload);
  },
  admin_list_hour(payload) {
    return Repository.post(`${resource}/admin/list_hour/`, payload);
  },
  admin_list_date_home() {
    return Repository.post(`${resource}/admin/list_date_home`);
  },
  admin_task_server_count() {
    return Repository.get(`${resource}/admin/task_server_count`);
  },
};
