<template>
  <v-card
    @click="cardClick"
    elevation="1"
    :class="`${
      loading ? 'bg_gray' : is_error ? 'bg_gray' : set_color
    } hover-card`"
    :loading="loading"
  >
    <v-card-title class="small-title"
      ><v-icon>{{ icon }}</v-icon
      >&nbsp;{{ card_title }}</v-card-title
    >
    <v-card-text v-if="!is_error" :class="`${body_class}`"
      ><slot></slot
    ></v-card-text>
    <v-card-text v-else>※データが取得できません</v-card-text>
    <v-icon class="hover-card-icon">mdi-chevron-right</v-icon>
  </v-card>
</template>

<script>
export default {
  props: {
    card_title: {
      type: String,
      required: true,
    },
    link_path: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    set_color: {
      type: String,
      default: "bg_white",
    },
    body_class: {
      type: String,
      default: "",
    },
    is_error: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const cardClick = () => {
      // props.link_pathに遷移
      ctx.root.$router.push(props.link_path);
    };

    return {
      props,
      cardClick,
    };
  },
};
</script>

<style scoped>
.small-title {
  font-size: 16px;
}
.hover-card {
  position: relative;
  transition: background-color 0.3s ease;
}
.hover-card:hover {
  opacity: 0.8;
}
.hover-card-icon {
  display: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.hover-card:hover .hover-card-icon {
  display: block;
}
.bg_ok {
  background-color: #ddeeff;
}
.bg_ng {
  background-color: #ffeeee;
}
.bg_gray {
  background-color: #eeeeee;
}
.bg_white {
  background-color: #ffffff;
}
</style>
