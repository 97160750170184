import Repository from "../repository";

const resource = "/informations";

export default {
  get(informationId) {
    return Repository.get(`${resource}/get/${informationId}`);
  },
  list(params, headers = {}) {
    return Repository.get(`${resource}/list`, { headers });
  },
  admin_get(informationId) {
    return Repository.get(`${resource}/admin/get/${informationId}`);
  },
  admin_list() {
    return Repository.get(`${resource}/admin/list`);
  },
  admin_create(payload) {
    return Repository.post(`${resource}/admin/create`, payload);
  },
  admin_update(payload) {
    return Repository.put(`${resource}/admin/update`, payload);
  },
  admin_delete(informationId) {
    return Repository.delete(`${resource}/admin/delete/${informationId}`);
  },
  admin_check(payload) {
    return Repository.post(`${resource}/admin/check`, payload);
  },
};
